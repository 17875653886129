
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
@keyframes opacity {
  0% {opacity: 0}
  10% {opacity: 1}
  90% {opacity: 1}
  100% {opacity: 0}
}

.modal {
  background: #cf7375;
  border-radius: 2px;
  padding: 20px;
  color: $color-light;
  font-size: $size-font-little;
  min-width: 200px;
  animation: opacity 5000ms ease;
  margin-bottom: 10px;

  @include tablet {
    min-width: 100px;
    padding: 10px;
  }

  &.success {
    background: #aacf73!important;
  }
}

.modal__title {
  font-size: $size-font-main;
  font-weight: $font-weight-bold;

  @include tablet {
    margin: 20px 0 5px 0;
    font-size: $size-font-small;
  }
}

.text {
  @include tablet {
    margin: 0 0 5px 0;
    font-size: $size-font-tiny;
  }
}
