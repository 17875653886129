
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.dashboard {
  position: fixed;
  transform: translateX(50%);
  right: 50%;
  bottom: 20px;
  z-index: 500;
  align-items: flex-end;
  @include tablet {
    display: block;
  }
}

.dashboard_wrap {
  display: flex;
  background: #f7f7f7;
  text-align: center;
  box-shadow: 0 6px 6px -3px #272C331A, 0 10px 14px 1px #272C330A, 0 4px 18px 3px #272C3305;
  z-index: 1;
  border-radius: 10px;
}

.logout__item {
  padding: 10px 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobileL {
    min-height: 40px;
    padding: 6px;
  }
}

.logout__text {
  margin: 0;
  font-size: $size-font-little;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  color: $color-main;

  @include mobileL {
    font-size: 10px;
  }
}

.logout__link {
  color: #ffc594;
  text-decoration: none;
}

.logout__link:hover {
  color: $color-main;
  transition: 0.3s;
}

.icon {
  @include mobileL {
    font-size: 1.4em;
  }
}
