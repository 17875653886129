
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.dashboard__item {
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobileL {
    min-height: 40px;
    padding: 6px;
  }
}

.dashboard__link {
  color: #ffc594;
  text-decoration: none;
  cursor: pointer;
}

.active {
  color:$color-main;
}

.dashboard__link:hover {
  color: $color-main;
  transition: 0.3s;
}

.dashboard__text {
  margin: 0;
  font-size: $size-font-little;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  color: $color-main;

  @include mobileL {
    font-size: 10px;
  }
}

.icon {
  @include mobileL {
    font-size: 1.4em;
  }
}
