
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  border: 1px solid #de6800;
  padding: 20px;
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.title {
  margin: 0;
  color: $color-main;
}

.button__container {
  margin-top: 20px;
}
