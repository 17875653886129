
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.couriers__item {
  align-items: center;
  border-bottom: 1px solid $color-middle;
}

.couriers__column {
  padding: 10px 15px;

  @include mobileL {
    font-size: $size-font-tiny;
    padding: 0 15px;
  }
}

.couriers__column_last {
  text-align: right;
  padding: 10px 15px;
  @include mobileL {
    padding: 0 15px;
  }
}

.icon {
  margin: 5px;
  cursor: pointer;
  z-index: 10;
  color: $color-main;
}
