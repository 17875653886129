
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.upload-image {
  &__upload-box {
    text-align: center;
  }

  &__wrap-img {
    max-width: 200px;
    width: 100%;
    overflow: hidden;
    transition: 0.5s;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: opacity(60%);
    }

    img {
      height: 100%;
      width: 100%;
    }

    input[type=file]{
      opacity:0;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      cursor: pointer;
    }
  }
}

.cropper {
  position: fixed;
  width: 100%;
  height: 90%;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.button_wrap {
  background-color: $color-light;
  height: 100%;
  text-align: center;
  padding-top: 15px;

  button {
    margin: 0 10px;
    @include mobileL {
      margin: 5px 10px;
    }
  }
}