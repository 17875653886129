
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.wrap {
  text-align: left;
  margin-bottom: 15px;
}

.wrap__label {
  display: block;
  margin-bottom: 5px;
  font-size: $size-font-small;

  @include tablet {
    font-size: $size-font-tiny;
  }
}

.wrap__input {
  max-width: 100%;
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  border: 1px solid $color-main;
  border-radius: 3px;
}

.error {
  font-size: $size-font-tiny;
  color: red;
}
