
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mobileL {
    display: block;
  }
}

.button__container {
  display: flex;
  justify-content: space-between;
  width: 49%;
  @include mobileL {
    width: 100%;
  }
}

.button__wrap {
  width: 49%;
}

