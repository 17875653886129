
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.wrap {
  text-align: left;
  margin-bottom: 15px;
}

.wrap__label {
  display: block;
  margin-bottom: 5px;
  font-size: $size-font-small;

  @include tablet {
    font-size: $size-font-tiny;
  }
}

.wrap__select {
  display: block;
  font-size: 14px;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 1px solid #ff7800;
  border-radius: 3px;
  outline: none;
}
