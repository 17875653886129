
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.react-calendar {
  position: relative;
  z-index: 999!important;
  @include tablet {
    width: auto!important;
  }
}

.react-confirm-alert-body {
  @include tablet {
    width: auto!important;
  }
}

body {
  font-family: $font-stack;
  font-size: $size-font-main;

  @include tablet {
    font-size: $size-font-small;
  }
}

.container {
  display: flex;
  min-height: 100vh;
  @include mobileL {
    padding-bottom: 80px;
  }
}

.wrapper {
  padding: 0 20px;

  @include tablet {
    padding: 0 10px;
  }
}

.pages {
  width: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal__wrap {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.modal__close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 10;
  color: $color-light;

  @include tablet {
    right: 10px;
    top: 10px;
  }
}

.modal__expire {
  position: relative;
}

.dossier {
  text-decoration: none;
  color: #1ebbf0!important;
  cursor: pointer;
}

.balloonRemove {
  cursor: pointer;
  color: $color-main;
}

.repair__wrap {
  margin: 5px 0;
}

.repair__wrap--title {
  font-weight: bold;
  font-size: 13px;
}

.repair__wrap--icon {
  color: red;
  font-size: 22px;
  vertical-align: middle;
}

.repair__wrap--text-title {
  color: #ff7800;
}

.repair__wrap--text {
  color: #000000;
}

.repair__wrap--text p {
  margin: 0;
}

.ymaps-2-1-79-balloon__close+.ymaps-2-1-79-balloon__content {
  margin-top: 20px;
  margin-right: 50px!important;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.iconMarker {
  width: 20px;
  height: 20px;
  border: 3px solid red;
  border-radius: 50%;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  flex-shrink: 0;
  z-index: 1;
}

.iconMarkerContent {
  flex-shrink: 0;
  background: #fff;
  margin-left: -28px;
  padding-left: 34px;
  padding-right: 10px;
  height: 28px;
  border-radius: 15px 5px 5px 15px;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.leaflet-popup-content {
  margin: 20px 30px;
}

.marker_avatar {
  width: 26px;
  height: 26px;
  margin-left: 5px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 26px;
  }
}
