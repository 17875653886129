
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.shipmentStatistics {
  margin: 20px 0;

  &__calendar_block {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 501;

    &--bg {
      background: grey;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 501;
      opacity: 0.5;
    }
  }

  &__button {
    width: 320px;
    background: #567c2b;
    margin-top: 5px;

    &:hover {
      background: #aacf73;
    }

    @include mobileL {
      width: 100%;
    }
  }

  &__period_wrapper {
    display: flex;

    @include mobileL {
      display: block;
    }
  }

  &__period_item {
    padding: 10px 20px;
    background: $color-main;
    color: $color-light;
    cursor: pointer;
    margin-right: 2px;
    border-radius: 4px;

    @include mobileL {
      margin-bottom: 5px;
      padding: 7px 20px;
    }
  }

  &__period_item_active {
    background: #ffac64;
  }
}

.table__wrapper {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.field__active {
  cursor: pointer;
}

.table__head {
  width: 100%;
  background: $color-main;
  padding: 10px 20px;
  margin-top: 20px;

  @include tablet {
    padding: 10px;
  }
}

.table__column {
  color: $color-light;
  font-size: $size-font-main;
  padding: 10px 15px;
  margin: 0;
  cursor: default;

  @include tablet {
    font-size: $size-font-small;
  }

  @include mobileL {
    font-size: $size-font-tiny;
  }
}

.empty {
  text-align: center;
  margin: 20px 0;
}
