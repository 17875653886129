
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.search {
    text-align: left;
    margin-bottom: 15px;
    position: relative;

    @include mobileL {
       margin-top: 15px;
    }
}

.search__input {
    max-width: 100%;
    width: 100%;
    padding: 8px 10px;
    box-sizing: border-box;
    border: 1px solid $color-main;
    border-radius: 3px;
    outline: none;

    @include mobileL {
        padding: 6px 10px;
    }
}

.icon_search {
    position: absolute;
    right: 10px;
    bottom: 50%;
    transform: translateY(50%);
    color: $color-main;
}