
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.button {
  padding: 10px;
  cursor: pointer;
  border: none;
  background: $color-main;
  color: $color-light;
  border-radius: 3px;
  width: 100%;

  &:disabled {
    background: #c9c9c9 !important;
    cursor: default;
  }

  @include mobileL {
    padding: 7px;
  }
}

button:hover {
  transition: 0.3s;
  background: #ff8c33;
}
