
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.couriers {
  margin: 20px 0;
}

.table__wrapper {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.field__active {
  cursor: pointer;
}

.table__head {
  width: 100%;
  background: $color-main;
  padding: 10px 20px;
  margin-top: 20px;

  @include tablet {
    padding: 10px;
  }
}

.table__column {
  color: $color-light;
  font-size: $size-font-main;
  padding: 10px 15px;
  margin: 0;

  @include tablet {
    font-size: $size-font-small;
  }

  @include mobileL {
    font-size: $size-font-tiny;
  }
}

.empty {
  text-align: center;
  margin: 20px 0;
}

.button__wrap {
  max-width: 190px;
  width: 100%;
  display: inline-block;
  margin-right: 20px;

  @include mobileL {
    max-width: 130px;
  }
}
