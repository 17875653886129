
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        

.shipmentStatisticsItem {
  &__item {
    align-items: center;
    border-bottom: 1px solid $color-middle;
  }

  &__column {
    padding: 10px 15px;

    @include mobileL {
      font-size: $size-font-tiny;
      padding: 0 15px;
    }
  }

  &__icon {
    margin: 5px;
    cursor: pointer;
    z-index: 10;
    color: $color-main;
  }

  &__calendar_block {
    position: absolute;
    right: 20px;
    z-index: 10;
    @include mobileL {
      right: 10px;
    }
  }
}
