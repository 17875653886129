
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.privacy {
  font: normal 15px "Arial", Helvetica, Arial, Verdana, sans-serif;
  word-spacing: normal;
  color: rgb(89, 89, 89);
  line-height: 20px;
}
.page_title .solid_bg {
  background-color: #f7f7f7;
}

.title {
  font-size: 26px
}

.title_1 {
  color: rgb(0, 0, 0);
  font-size: 19px;
  margin: 40px 0;
}

.black {
  color: #000000;
}

.page_title .wf_wrap {
  display: flex;
  min-height: 140px;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-flex-pack: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-flow: column wrap;
  -moz-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  padding-top: 25px;
  padding-bottom: 20px;
}

.page_title h1 {
  font: normal bold 30px / 40px "Roboto", Helvetica, Arial, Verdana, sans-serif;
  color: #333;
  text-transform: uppercase;
}

#main {
  padding: 70px 0 80px 0;
}

.date {
  margin-bottom: 40px;
}

.wf_wrap {
  max-width: 100%;
  padding: 0 40px;
  margin: 0 auto;
}
.textwidget, h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 10px;
  margin-top: 0;
}
a {
  color: #1ebbf0;
}
a:hover {
  text-decoration: none;
}
