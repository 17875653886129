
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.edit {
  padding: 40px;
  max-width: 800px;

  @include tablet {
    padding: 20px;
  }
}

.title {
  margin: 0;
  color: $color-main;
  margin-bottom: 20px;

  @include tablet {
    font-size: $size-font-large;
  }
}
