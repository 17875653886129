
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.finished_list {
  position: relative;
  padding: 0 0 5px;
  span {
    color: $color-dark;
    margin-right: 20px;
  }
}

.red_circle {
  display: inline-block;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
