
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.list-product {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 500;
  background: $color-main;
  padding-top: 10px;
  box-shadow: 0 0 12px #909090;

  @include mobileL {
    top: 80px;
    left: 20px;
  }

  &__lock {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  &--title {
    text-align: center;
    color: $color-light;
  }

  &--not-border {
    border: none!important;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--sum-on-way {
    background: yellow!important;
    border-color: $color-dark!important;
    color: red;
  }

  td {
    border: 1px solid;
    padding: 5px;
    background: $color-light;
    text-align: center;
  }

  table {
    border: 2px solid white;
    border-collapse: collapse;
    border-radius: 10px;
  }
}
