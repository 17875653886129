$font-stack: 'Roboto', sans-serif;

$color-light: #ffffff;
$color-main: #ff7800;
$color-middle: #7a7a7a;
$color-dark: #000000;

$size-font-tiny: 10px;
$size-font-small: 12px;
$size-font-little: 13px;
$size-font-main: 14px;
$size-font-large: 16px;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;
