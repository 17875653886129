
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.edit__courier {
  padding: 40px;
  max-width: 800px;

  @include tablet {
    padding: 20px;
  }
}

.title {
  margin: 0;
  color: $color-main;
  margin-bottom: 20px;

  @include tablet {
    font-size: $size-font-large;
  }
}

.stars_wrap {
  min-width: 49%;
  margin-bottom: 15px;
}

.stars_wrap span {
  margin-bottom: 5px;
  font-size: 12px;
}

.stars span {
  @include tablet {
    font-size: 20px!important;
  }
  @include mobileL {
    font-size: 30px!important;
  }
}

.upload_wrap {
  min-width: 49%;
  margin-bottom: 15px;
  p {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
  }
}

.upload_container {
  min-width: 49%;
}

