
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 65px;
}

.main_color {
  color: $color-main;
  font-weight: $font-weight-bold;
}

.pagination__size {
  padding-top: 40px;
}

.pagination__text {
  font-weight: $font-weight-bold;
  cursor: pointer;
  padding: 3px;
  color: $color-main;
  opacity: 0.5;
}

.active {
  opacity: 1;
}

.pagination__wrap {
  flex: 1;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }
}

