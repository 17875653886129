
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.list {
  padding: 0 0 5px;
  &__route {
    transition: 0.3s;
    cursor: pointer;
    font-weight: $font-weight-bold;
    &:hover {
      opacity: 0.7;
    }
  }
  span {
    color: $color-main;
  }
}

.red_circle {
  display: inline-block;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

