
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.support {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.support_form {
  border: 1px solid #de6800;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.support_success {
  color: green;
}

.support_err {
  color: red;
}

.support_title {
  margin: 0 0 20px 0;
  color: $color-main;
}

.support_button__container {
  margin-top: 20px;
}
