
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.shipment-statistic-user-routes {
  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
  }

  &__head {
    width: 100%;
    background: $color-main;
    padding: 10px 20px;
    margin-top: 20px;

    @include tablet {
      padding: 10px;
    }
  }

  &__column {
    color: $color-light;
    font-size: $size-font-main;
    padding: 10px 15px;
    margin: 0;
    cursor: default;

    @include tablet {
      font-size: $size-font-small;
    }

    @include mobileL {
      font-size: $size-font-tiny;
    }
  }

  &__item {
    align-items: center;
    border-bottom: 1px solid $color-middle;

    &--column {
      padding: 10px 15px;

      @include mobileL {
        font-size: $size-font-tiny;
        padding: 0 15px;
      }
    }
  }

  &__icon {
    margin: 5px;
    cursor: pointer;
    z-index: 10;
    color: $color-main;
  }

  &__button {
    width: 160px;
    margin: 20px;

    &:hover {
      background: #aacf73;
    }

    @include mobileL {
      width: 100%;
    }
  }
}
