
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.map {
  width: 100%;
  height: 100%;
}

.logo {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 10000;
  max-width: 60px;
  width: 100%;

  @include tablet {
    max-width: 40px;
  }
}

.balloon_content {
  font-size: 13px;
}

.balloon_full_name {
  font-weight: 500;
}
